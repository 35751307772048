import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "cardElement", "cardContainer", "cardErrors", "submitButton", "spinner", "submitText", "resultMessage"]

  initialize() {
    this.requiredFieldSelectors = 'input:required, textarea:required'
  }

  connect() {
    if (this.hasCardElementTarget) {
      // this.getPaymentIntent()
      this.configureCardElement()
    }
  }

  // getPaymentIntent() {
  //   fetch(this.data.get('paymentGenerateUrl'))
  //     .then(res => console.log(res))
    //   .then(function(result) {
    //   if (result.error) {
    //     controller.showError(result.error.message)
    //   } else {
    //     this.payment_id = (result.paymentIntent.id)
    //     this.clientSecret = (result.paymentIntent.id)
    //     console.log(this.payment_id)
    //     console.log(this.clientSecret)
    //   }
    // })
  // }

  configureCardElement() {
    this.stripe = Stripe(this.data.get('stripeKey'))
    let elements = this.stripe.elements()
    const style = {
      base: {
        fontSmoothing: 'antialiased',
        fontSize: '24px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#dc3545',
        iconColor: '#dc3545'
      }
    }

    this.card = elements.create("card", { style: style })
    this.card.mount(this.cardElementTarget)

    // handle real-time validation errors from the card element
    let controller = this
    this.card.addEventListener('change', function (event) {
      controller.cardErrorsTarget.textContent = event.error ? event.error.message : ''
      controller.submitButtonTarget.disabled = !event.complete
    })
  }

  validateForm(form) {
    let isValid = true;

    let requiredFields = form.querySelectorAll(this.requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus()
        isValid = false
        return
      }
    })

    if (!isValid) {
      return false
    }

    let invalidFields = form.querySelectorAll('input:invalid')

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();

        isValid = false
        return
      }
    })

    return isValid
  }

  // intercept form submission (so we can capture the token and add it to the form data)
  submit(e) {
    e.preventDefault()
    this.payWithCard(this.card, this.data.get('paymentIntent'))
  }

  payWithCard(card, clientSecret) {
    let controller = this

    controller.loading(true)

    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: card }
    })
    .then(function(result) {
      if (result.error) {
        controller.showError(result.error.message)
      } else {
        controller.startPollingForPaymentCompleteState(result.paymentIntent.id)
        // controller.orderComplete(result.paymentIntent.id)
      }
    })
  }

  startPollingForPaymentCompleteState(paymentIntentId) {
    this.refreshTimer = setInterval(() => {
      this.pollForPaymentCompleteState(paymentIntentId)
    }, this.data.get('refreshInterval'))
  }

  pollForPaymentCompleteState(paymentIntentId) {
    let controller = this

    fetch(this.data.get('paymentCompleteUrl'))
      .then(response => response.json())
      .then(paymentComplete => {
        if (paymentComplete) {
          controller.orderComplete(paymentIntentId)

          if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
          }
        }
      })
  }

  // Shows a success message when the payment is complete
  orderComplete(paymentIntentId) {
    this.loading(false)

    this.cardContainerTarget.classList.add("d-none")
    this.resultMessageTarget.classList.remove("d-none")
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.hidden = true
  }

  showError(message) {
    let controller = this

    controller.loading(false)

    controller.cardErrorsTarget.textContent = message
    setTimeout(function() {
      controller.cardErrorsTarget.textContent = ""
    }, 4000)
  }

  loading(isLoading) {
    if (isLoading) {
      this.submitButtonTarget.disabled = true
      this.spinnerTarget.classList.remove("d-none")
      this.submitTextTarget.classList.add("d-none")
    } else {
      this.submitButtonTarget.disabled = false
      this.spinnerTarget.classList.add("d-none")
      this.submitTextTarget.classList.remove("d-none")
    }
  }
}
