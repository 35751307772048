const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

export const fetchCases = (inputValue) => {
  return fetch(`/admin/cases/search?term=${inputValue}`)
    .then((response) => response.json())
    .then((data) => data.map((c) => ({ value: c.value, label: c.label })));
};

export const linkCase = (caseId, linkedCaseId) => {
  return fetch(`/admin/cases/${caseId}/link_case`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ linked_case_id: linkedCaseId }),
  }).then((response) => response.json());
};
