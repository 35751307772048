import React, { useState, useEffect } from "react";
import Select from "react-select";
import { fetchCases, linkCase } from "../lib/apiService";

const LinkCaseForm = ({ caseId }) => {
  const [selectedCase, setSelectedCase] = useState(null);
  const [cases, setCases] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    fetchCases(inputValue).then((response) => {
      setCases(response.map((c) => ({ value: c.value, label: c.label })));
    });
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    linkCase(caseId, selectedCase.value).then((response) => {
      window.location.reload();
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Select
        onInputChange={handleInputChange}
        onChange={setSelectedCase}
        options={cases}
      />
      <button type="submit">Link Case</button>
    </form>
  );
};

export default LinkCaseForm;
