import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { Chat, Channel, ChannelList } from "stream-chat-react";
import { useChecklist } from "./ChecklistTasks";

import "@stream-io/stream-chat-css/dist/css/index.css";
import "./App.css";

import {
  CreateChannel,
  CustomMessage,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThreadHeader,
  ChannelSearch,
} from "./components";

import { ChannelInner } from "./components/ChannelInner/ChannelInner";

const urlParams = new URLSearchParams(window.location.search);

const apiKey = urlParams.get("apikey") || process.env.REACT_APP_STREAM_KEY;
const user = urlParams.get("user") || process.env.REACT_APP_USER_ID;
const userToken =
  urlParams.get("user_token") || process.env.REACT_APP_USER_TOKEN;
const targetOrigin =
  urlParams.get("target_origin") || process.env.REACT_APP_TARGET_ORIGIN;

const noChannelNameFilter = urlParams.get("no_channel_name_filter") || false;
const skipNameImageSet = urlParams.get("skip_name_image_set") || false;

export const GiphyContext = React.createContext({});

const App = ({
  stream_key,
  stream_app_id,
  stream_user_token,
  stream_user_id,
  parent_id,
  busy_bee_id,
}) => {
  let filters = { type: "messaging", members: { $in: [stream_user_id] } };
  if (parent_id) {
    filters = {
      type: "messaging",
      members: { $in: [stream_user_id], $in: ["Parent-" + parent_id] },
    };
  }

  if (busy_bee_id) {
    filters = {
      type: "messaging",
      members: { $in: [stream_user_id], $in: ["BusyBee-" + busy_bee_id] },
    };
  }

  const options = { state: true, watch: true, presence: true, limit: 30 };

  const sort = {
    last_message_at: -1,
    updated_at: -1,
  };

  const userToConnect = {
    id: stream_user_id,
    name: stream_user_id,
  };

  if (skipNameImageSet) {
    delete userToConnect.name;
    delete userToConnect.image;
  }

  const [filter, setFilter] = useState(filters);
  const [chatClient, setChatClient] = useState(null);
  const [giphyState, setGiphyState] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("light");

  useChecklist(chatClient, targetOrigin);

  useEffect(() => {
    const initChat = async () => {
      const client = StreamChat.getInstance(stream_key, {
        enableInsights: true,
        enableWSFallback: true,
      });
      await client.connectUser(userToConnect, stream_user_token);
      setChatClient(client);
    };

    initChat();

    return () => chatClient?.disconnectUser();
  }, []); // eslint-disable-line

  useEffect(() => {
    const handleThemeChange = ({ data, origin }) => {
      // handle events only from trusted origin
      if (origin === targetOrigin) {
        if (data === "light" || data === "dark") {
          setTheme(data);
        }
      }
    };

    window.addEventListener("message", handleThemeChange);
    return () => window.removeEventListener("message", handleThemeChange);
  }, []);

  // useEffect(() => {
  //   const mobileChannelList = document.querySelector("#mobile-channel-list");
  //   if (isMobileNavVisible && mobileChannelList) {
  //     mobileChannelList.classList.add("show");
  //     document.body.style.overflow = "hidden";
  //   } else if (!isMobileNavVisible && mobileChannelList) {
  //     mobileChannelList.classList.remove("show");
  //     document.body.style.overflow = "auto";
  //   }
  // }, [isMobileNavVisible]);

  const toggleMobile = () => setMobileNav(false);

  const giphyContextValue = { giphyState, setGiphyState };

  if (!chatClient) return null;

  return (
    <Chat client={chatClient} theme={`messaging ${theme}`}>
      <div id="mobile-channel-list" onClick={toggleMobile}>
        <ChannelList
          filters={filters}
          sort={sort}
          options={options}
          allowNewMessagesFromUnfilteredChannels={
            parent_id || busy_bee_id ? false : true
          }
          List={(props) => (
            <MessagingChannelList
              {...props}
              onCreateChannel={() => setIsCreating(!isCreating)}
              onChannelFilter={() => setIsCreating(false)}
            />
          )}
          Preview={(props) => (
            <MessagingChannelPreview {...props} {...{ setIsCreating }} />
          )}
        />
      </div>
      <div>
        <Channel
          Input={MessagingInput}
          maxNumberOfFiles={10}
          Message={CustomMessage}
          multipleUploads={true}
          ThreadHeader={MessagingThreadHeader}
          TypingIndicator={() => null}
        >
          {isCreating && (
            <CreateChannel
              toggleMobile={toggleMobile}
              onClose={() => setIsCreating(false)}
            />
          )}
          <GiphyContext.Provider value={giphyContextValue}>
            <ChannelInner theme={theme} toggleMobile={toggleMobile} />
          </GiphyContext.Provider>
        </Channel>
      </div>
    </Chat>
  );
};

export default App;
