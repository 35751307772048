import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "receipt", "purpose" ]

  connect() {
    const controller = this

    fetch(this.data.get('url'))
      .then(response => response.json())
      .then(json => {
        controller.receiptTarget.innerHTML = json.receipt
        controller.purposeTarget.innerHTML = json.purpose
      })
      .catch(error => {
        console.log(error)
      })
  }
}
