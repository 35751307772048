import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as apiService from "../lib/apiService";
import "./AdminTaskKanban.css";

export default {};
// const Task = ({ task, index }) => (
//   <Draggable draggableId={String(task.id)} index={index}>
//     {(provided) => (
//       <div
//         ref={provided.innerRef}
//         {...provided.draggableProps}
//         {...provided.dragHandleProps}
//         className="task"
//       >
//         <div>Task: {task.id}</div>
//       </div>
//     )}
//   </Draggable>
// );

// const Column = ({ tasks, status, title }) => (
//   <div className="column-container">
//     <h1>{title}</h1>
//     <Droppable droppableId={status}>
//       {(provided, snapshot) => (
//         <div
//           className={`column ${
//             snapshot.isDraggingOver ? "column--is-dragging-over" : ""
//           }`}
//           ref={provided.innerRef}
//           {...provided.droppableProps}
//         >
//           <div
//             className={`task-container ${
//               snapshot.isDraggingOver ? "task-container--is-dragging-over" : ""
//             }`}
//           >
//             {tasks.map((task, index) => (
//               <Task key={task.id} task={task} index={index} />
//             ))}
//             {provided.placeholder}
//           </div>
//         </div>
//       )}
//     </Droppable>
//   </div>
// );

// const AdminTaskKanban = ({ tasks }) => {
//   const [columns, setColumns] = React.useState({
//     pending: {
//       title: "Pending",
//       tasks: tasks.filter((task) => task.status === "pending"),
//       action: apiService.markTaskAsPending,
//     },
//     in_progress: {
//       title: "In Progress",
//       tasks: tasks.filter((task) => task.status === "in_progress"),
//       action: apiService.startTask,
//     },
//     completed: {
//       title: "Completed",
//       tasks: tasks.filter((task) => task.status === "completed"),
//       action: apiService.completeTask,
//     },
//   });

//   const onDragEnd = async (result) => {
//     const { destination, source, draggableId } = result;

//     if (!destination) return;

//     if (
//       destination.droppableId === source.droppableId &&
//       destination.index === source.index
//     )
//       return;

//     const start = columns[source.droppableId];
//     const finish = columns[destination.droppableId];

//     if (start === finish) {
//       const newTaskIds = Array.from(start.tasks);
//       const task = start.tasks.find((task) => task.id == draggableId);
//       newTaskIds.splice(source.index, 1);
//       newTaskIds.splice(destination.index, 0, task);

//       const newColumn = {
//         ...start,
//         tasks: newTaskIds,
//       };

//       setColumns({
//         ...columns,
//         [source.droppableId]: newColumn,
//       });
//     } else {
//       const startTaskIds = Array.from(start.tasks);
//       startTaskIds.splice(source.index, 1);
//       const task = start.tasks.find((task) => task.id == draggableId);
//       const newStart = {
//         ...start,
//         tasks: startTaskIds,
//       };

//       const finishTaskIds = Array.from(finish.tasks);
//       finishTaskIds.splice(destination.index, 0, task);
//       const newFinish = {
//         ...finish,
//         tasks: finishTaskIds,
//       };

//       setColumns({
//         ...columns,
//         [source.droppableId]: newStart,
//         [destination.droppableId]: newFinish,
//       });

//       const action = columns[destination.droppableId].action;
//       if (action) {
//         await action(task.id);
//       }
//     }
//   };

//   return (
//     <div className="kanban-board">
//       <DragDropContext onDragEnd={onDragEnd}>
//         {Object.entries(columns).map(([status, { title, tasks }]) => (
//           <Column key={status} status={status} title={title} tasks={tasks} />
//         ))}
//       </DragDropContext>
//     </div>
//   );
// };

// export default AdminTaskKanban;
