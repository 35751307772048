import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ];

  copy() {
    var range = document.createRange()
    range.selectNode(this.sourceTarget)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  }
}
