import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitButton", "spinner", "submitText"]

  connect() {
    console.log("spinner-form")
    this.hideSpinner()
  }

  showSpinner() {
    console.log("showSpinner")
    this.submitButtonTarget.disabled = true
    this.spinnerTarget.classList.remove("d-none")
    this.submitTextTarget.classList.add("d-none")
  }

  hideSpinner() {
    console.log("hideSpinner")
    this.submitButtonTarget.disabled = false
    this.spinnerTarget.classList.add("d-none")
    this.submitTextTarget.classList.remove("d-none")
  }
}
